import React from 'react';
import Post from './Post';
import { SectionTitleCss, BlogListWrapperCss } from './index.css';

const BlogList = ({ posts }) => {
  return (
    <BlogListWrapperCss>
      <SectionTitleCss>RECENTLY PUBLISHED</SectionTitleCss>
      {posts.map(post => (
        <Post postData={post.node} key={post.node.id} />
      ))}
    </BlogListWrapperCss>
  );
};

export default BlogList;
