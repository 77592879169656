import styled from 'styled-components';
export const BlogListWrapperCss = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SectionTitleCss = styled.h2`
  color: ${props => props.theme.secondary};
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 2px;
  margin-bottom: 40px;
`;

// ***** POST ***** //

export const PostTitleCss = styled.h3`
  font-size: 22px;
  color: ${props => props.theme.gray1000};
  margin-bottom: 16px;
`;

export const PostDescriptionCss = styled.p`
  color: ${props => props.theme.text};
  font-size: 16px;
  margin-bottom: 16px;
  line-height: 1.6;
  font-weight: 300;
`;

export const ReadMoreCss = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${props => props.theme.text};
  svg {
    opacity: 0;
    margin-left: 5px;
    width: 12px;
    height: 12px;
    path {
      fill: ${props => props.theme.text};
    }
  }
`;
export const PostCss = styled.div`
  border-radius: 7px;
  padding: 10px 0;
  margin-bottom: 40px;
  cursor: pointer;
  &:hover {
    ${PostTitleCss} {
      color: ${props => props.theme.primary};
    }
    svg {
      opacity: 1;
      animation: slide 300ms cubic-bezier(0.64, 0.57, 0.67, 1.53);
    }
  }

  @keyframes slide {
    0% {
      margin-left: 5px;
    }

    50% {
      margin-left: 15px;
    }

    100% {
      margin-left: 5px;
    }
  }
`;
