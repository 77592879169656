import React from 'react';
import { HomepageWrapperCss, LeftSideCss, RightSideCss } from './index.css';
import StickMan from './StickMan';
import BlogList from '../BlogList';

const Homepage = ({ posts }) => {
  return (
    <HomepageWrapperCss>
      <LeftSideCss>
        <BlogList posts={posts} />
      </LeftSideCss>
      <RightSideCss>
        <StickMan />
      </RightSideCss>
    </HomepageWrapperCss>
  );
};

export default Homepage;
