import React from 'react';
import { Link } from 'gatsby';
import {
  PostCss,
  ReadMoreCss,
  PostTitleCss,
  PostDescriptionCss,
} from './index.css';
import RightArrowSvg from '../../images/svg/right-arrow.svg';

const Post = ({ postData }) => {
  return (
    <Link to={postData.fields.slug}>
      <PostCss>
        <PostTitleCss>{postData.frontmatter.title}</PostTitleCss>
        <PostDescriptionCss>{postData.excerpt}</PostDescriptionCss>
        <ReadMoreCss>
          Read more <RightArrowSvg />
        </ReadMoreCss>
      </PostCss>
    </Link>
  );
};

export default Post;
