import styled from 'styled-components';

export const HomepageWrapperCss = styled.div`
  display: flex;
  margin-top: 200px;

  @media (max-width: ${props => props.theme.breakpoints.TABLET + 'px'}) {
    margin-top: 100px;
  }
`;

export const HeroTextCss = styled.h1`
  position: relative;
  color: ${props => props.theme.text};
  font-size: 20px;
  line-height: 32px;
  span {
    color: ${props => props.theme.tertiary};
  }

  @media (max-width: ${props => props.theme.breakpoints.TABLET + 'px'}) {
    font-size: 26px;
    line-height: 30px;
    text-align: center;
  }
`;

export const LeftSideCss = styled.div`
  width: 60%;
  padding: 0 15px 0 0;
  display: flex;
  justify-content: center;

  @media (max-width: ${props => props.theme.breakpoints.TABLET + 'px'}) {
    width: 100%;
  }
`;
export const RightSideCss = styled.div`
  width: 40%;
  padding: 0 0 0 15px;
  display: flex;
  justify-content: center;
  @media (max-width: ${props => props.theme.breakpoints.TABLET + 'px'}) {
    display: none;
  }
`;

export const ThoughtWrapperCss = styled.div`
  position: absolute;
  right: 100px;
  width: 500px;
  top: -80%;
  opacity: 0;
  transition: opacity 200ms ease 0s;
  pointer-events: none;
  padding-bottom: 100px;
`;

export const StickManWrapperCss = styled.div`
  height: 200px;
  width: 200px;
  position: relative;
  margin-left: auto;
  z-index: 180;
  background: ${props => props.theme.background};
  border-radius: 50%;
  box-shadow: 0px 0px 35px 35px ${props => props.theme.background};

  #rest-hand {
    display: block;
  }
  #wave-hand {
    display: none;
  }

  &:hover {
    ${ThoughtWrapperCss} {
      opacity: 1;
      pointer-events: all;
    }
    #rest-hand {
      display: none;
    }
    #wave-hand {
      display: block;
    }
  }
`;

export const SvgWrapperCss = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  animation: updown 4s ease-in-out infinite;
  @keyframes updown {
    0% {
      transform: translateY(10px);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(10px);
    }
  }
  .monster {
    position: relative;
    height: 50%;
    width: 50%;
    margin-left: 0px;
    z-index: 10;
  }
  .island {
    width: 80%;
    height: 80%;
    margin-top: -45px;
  }

  .piggy {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 45px;
    top: 75px;
    z-index: 15;
  }
`;
