import React, { useEffect } from 'react';
import IslandSvg from '../../images/svg/island/island-4.svg';
import MonsterSvg from '../../images/svg/monster/monster-4.svg';
import PiggySvg from '../../images/svg/monster/my-piggy.svg';
import Thought from '../ThoughtCloud';
import {
  ThoughtWrapperCss,
  HeroTextCss,
  StickManWrapperCss,
  SvgWrapperCss,
} from './index.css';

const StickMan = () => {
  useEffect(() => {
    const leftEye = document.getElementById('left-eye');
    const rightEye = document.getElementById('right-eye');
    const maxOffset = 27;
    const getOffset = (mouseX, mouseY, centerLeftEye, centerRightEye) => {
      // keep it in the center
      let offsetX = (mouseX - (centerRightEye.x + centerLeftEye.x) / 2) / 10;

      // Keep it between the limits
      if (offsetX < -maxOffset) {
        offsetX = -maxOffset;
      } else if (offsetX > maxOffset) {
        offsetX = maxOffset;
      }
      let offsetY = (mouseY - centerLeftEye.y) / 5;

      // Keep it between the limits
      if (offsetY < -maxOffset) {
        offsetY = -maxOffset;
      } else if (offsetY > maxOffset) {
        offsetY = maxOffset;
      }
      return [offsetX, offsetY];
    };
    function moveEyes(e) {
      if (!e) return;
      const mouseX = e.pageX;
      const mouseY = e.pageY;

      const pageScrollOffset = window.scrollY;
      const valuesLeftEye = leftEye.getBoundingClientRect();
      const centerLeftEye = {
        x: valuesLeftEye.x + valuesLeftEye.width / 2,
        y: valuesLeftEye.y + pageScrollOffset + valuesLeftEye.height / 2,
      };
      const valuesRightEye = rightEye.getBoundingClientRect();
      const centerRightEye = {
        x: valuesRightEye.x + valuesRightEye.width / 2,
        y: valuesRightEye.y + pageScrollOffset + valuesRightEye.height / 2,
      };

      const [leftOffsetX, leftOffsetY] = getOffset(
        mouseX,
        mouseY,
        centerLeftEye,
        centerRightEye
      );

      leftEye.style.transform = `translate(${leftOffsetX}px, ${leftOffsetY}px)`;
      rightEye.style.transform = `translate(${leftOffsetX}px, ${leftOffsetY}px)`;
    }
    window.addEventListener('mousemove', moveEyes, false);

    return () => {
      window.removeEventListener('mousemove', moveEyes, false);
    };
  }, []);

  return (
    <StickManWrapperCss>
      <ThoughtWrapperCss>
        <Thought>
          <HeroTextCss>
            Hello{' '}
            <span role="img" aria-label="hi">
              👋
            </span>{' '}
            I am Razvan, <span>React developer</span> and technology enthusiast{' '}
            <span role="img" aria-label="tada">
              🎉
            </span>
          </HeroTextCss>
        </Thought>
      </ThoughtWrapperCss>
      <SvgWrapperCss>
        <MonsterSvg className="monster" />
        <IslandSvg className="island" />
        <PiggySvg className="piggy" />
      </SvgWrapperCss>
    </StickManWrapperCss>
  );
};

export default StickMan;
